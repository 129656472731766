/* Reusable, object-oriented CSS base components for all Zulip pages
   (both web app and portico). */

& label.checkbox {
    padding: 0;
    display: inline-block;
    position: relative;
    vertical-align: top;
    min-height: 20px;

    & input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);

        ~ .rendered-checkbox {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: -2px;

            padding: 2px;
            margin: 0 5px 0 0;
            height: 10px;
            width: 10px;

            font-weight: 300;
            line-height: 0.8;
            font-size: 1.3rem;
            text-align: center;
            /* Color variables are not imported to the portico, so we duplicate
               the light-mode color value here as a fallback. */
            border: 1px solid
                var(--color-border-rendered-checkbox, hsl(0deg 0% 0% / 60%));
            border-radius: 4px;

            cursor: pointer;
        }

        &:checked ~ .rendered-checkbox {
            /* As with the border color above, we duplicate
               the light-mode SVG URL value here as a fallback. */
            background-image: var(
                --svg-url-rendered-checkbox,
                url("../images/checkbox.svg")
            );
            background-size: 85%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }

        &:disabled ~ .rendered-checkbox {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &:has(.enter_sends) {
        vertical-align: middle;
    }
}

a.no-style {
    color: inherit;
}

a.no-underline,
a.no-underline:hover {
    text-decoration: none;
}

.italic {
    font-style: italic;
}

.simplebar-track {
    .simplebar-scrollbar::before {
        background-color: hsl(0deg 0% 0%);
        box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 33%);
    }

    &.simplebar-vertical {
        transition: width 0.2s ease 1s;

        &.simplebar-hover {
            width: 15px;
            transition: width 0.2s ease;
        }
    }

    &.simplebar-horizontal {
        transition: height 0.2s ease 1s;

        &.simplebar-hover {
            height: 15px;
            transition: height 0.2s ease;
        }
    }
}

i.zulip-icon.zulip-icon-bot {
    color: var(--color-icon-bot);
}

.tippy-content {
    /* Set the default font size for tooltips. Popovers override this with
       a rule looking at the data-theme.
     */
    font-size: 12px;
}

/* Hide the somewhat buggy browser show password feature in IE, Edge,
   since it duplicates our own "show password" widget. */
input::-ms-reveal {
    display: none;
}

.password-div {
    position: relative;

    .password_visibility_toggle {
        position: absolute;
        right: 10px;
        top: 42px;
        opacity: 0.6;

        &:hover {
            opacity: 1;
        }
    }
}

select.bootstrap-focus-style {
    &:focus {
        outline: 1px dotted hsl(0deg 0% 20%);
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
        transition: none;
    }
}
